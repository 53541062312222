<template>
    <div class="h-screen w-full relative flex flex-col-reverse">
        <transition
                enter-active-class="duration-1000 ease-out"
                enter-from-class="transform opacity-0 delay-1000"
                enter-to-class="opacity-100"
                leave-active-class="duration-200 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="transform opacity-0"
            >
            <div class="rounded-md p-3 mt-2 mb-8 bg-green-800" v-if="isEligible && hasSearched">
                <p class="text-white font-bold md:text-2xl text-base">Yes! That property is USDA eligible.</p>
            </div>

            <div class="rounded-md p-3 mt-2 mb-8 bg-red-800" v-else-if="!isEligible && hasSearched">
                <p class="text-white font-bold md:text-2xl text-base">Sorry, that property is NOT USDA eligible.</p>
            </div>
        </transition>
        
        <div id="viewDiv" class="balt-theme"></div>
    </div>
</template>
<script>
import { loadModules } from 'esri-loader';
import axios from 'axios';
export default {
    data() {
        return {
            isEligible: false,
            hasSearched: false,
            searchInput: ''
        }
    },
    mounted() {
        this.loadMap();
    },
    methods: {
        loadMap() {
        loadModules([
            'esri/Map', 
            'esri/views/MapView',
            "esri/widgets/Search",
            "esri/layers/MapImageLayer"
            ], {
                css: true
            })
            .then(([ArcGISMap, MapView, Search, MapImageLayer]) => {

                const layer = new MapImageLayer({
                    url: "https://rdgdwe.sc.egov.usda.gov/arcgis/rest/services/Eligibility/Eligibility/MapServer",
                    title: "USDA Layer",
                    imageTransparency: true,
                    opacity: 0.5,
                    sublayers: [
                    {
                        id: 11,
                        title: "ineligilbe layer",
                        color: "blue",
                    }]
                });

                // create map with the given options
                const map = new ArcGISMap({
                    basemap: 'osm',
                    layers: [layer]
                });

                // assign map to this view
                this.view = new MapView({
                    container: this.$el,
                    map: map,
                    center: [-95.7129, 37.0902],
                    zoom: 4,
                    navigation: {
                        mouseWheelZoomEnabled: false,
                        browserTouchPanEnabled: false
                    },
                });

                this.search = new Search({
                    view: this.view
                }, 'search');

                this.search.on("search-complete", (result) => {
                    var geom = result.results[0].results[0].feature.geometry;
                    if(geom) {
                        const lat = geom.latitude;
                        const long = geom.longitude;

                        let requestUrl = `https://rdgdwe.sc.egov.usda.gov/arcgis/rest/services/Eligibility/Eligibility/MapServer/2/query?geometry=${long},${lat}&geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&outFields=*&returnGeometry=false&f=pjson`;
                        axios.get(requestUrl)
                            .then((data) => {
                                this.hasSearched = true;
                                let isEligible = data.data.features;
                                if(isEligible && isEligible.length) {
                                    this.isEligible = false;
                                } else {
                                    this.isEligible = true;
                                }
                        });
                    }
                });

                this.view.ui.add(this.search, "top-right");
            });
        }
    }
}
</script>
<style>
    /* esri styles */
    @import url('https://js.arcgis.com/4.4/esri/themes/light/main.css');
    html, body, #viewDiv {
        padding: 0;
        margin: 0;
        height: 100%;
        width: 100%;
    }   
</style>