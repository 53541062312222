import { createWebHistory, createRouter } from "vue-router";
import EligibilityMap from "../components/EligibilityMap";

const routes = [
    {
        path: "/",
        name: "EligibilityMap",
        component: EligibilityMap,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;