<template>
    <div class="m-4 h-full p-4 shadow-2xl rounded-md">
        <h1 class="md:text-4xl text-3xl font-bold">USDA Loan Property Eligibility Map</h1>
        <p>
            You can use this USDA eligibility map to find USDA-eligible project locations in your area. 
            Look up the address you’re interested in purchasing to verify it falls within a rural area
        </p>

        <div class="w-full h-full mt-2">
            <Map></Map>
        </div>
    </div>
</template>
<script>
    // import {Loader} from '@googlemaps/js-api-loader';
    // import axios from 'axios';
    import Map from '../components/Map.vue';
    export default {
        components: {
            Map
        },
        data() {
            return {
                isEligible: false,
                hasSearched: false,
                searchInput: ''
            }
        },
        methods: {
            checkSearch() {
                if(this.searchInput === '') {
                    this.hasSearched = false;
                }
            }
        },
        mounted() {
            // const loader = new Loader({
            //     apiKey: "AIzaSyC75_flZLTeckKqmryx9pxK0BTA9O3GXic",
            //     version: "weekly",
            //     libraries: ["geometry","places"]
            // });

            // const mapOptions = {
            //     center: {
            //         lat: 35.46633158025032,
            //         lng: -97.50568501534394
            //     },
            //     zoom: 10,
            //     disableDefaultUI: true,
            // }

            // loader.load()
            //     .then((google) => {
            //         var map = new google.maps.Map(document.getElementById("map"), mapOptions);

            //         const input = document.getElementById("pac-input");
            //         const searchBox = new google.maps.places.SearchBox(input);

            //         map.addListener("bounds_changed", () => {
            //             searchBox.setBounds(map.getBounds());
            //         });

            //         let markers = [];

            //         // Listen for the event fired when the user selects a prediction and retrieve
            //         // more details for that place.
            //         searchBox.addListener("places_changed", () => {
            //             const places = searchBox.getPlaces();

            //             if (places.length == 0) {
            //                 return;
            //             }

            //             let requestUrl = `https://www.mortgageresearchcenter.com/ajax/usda_coordinates_eligibility.php?
            //             lat=${places[0].geometry.location.lat()}&lng=${places[0].geometry.location.lng()}`;
            //             axios
            //                 .get(requestUrl)
            //                 .then((data) => {
            //                     this.hasSearched = true;
            //                     let isEligible = data.data;
            //                     if(isEligible === 'Eligible') {
            //                         this.isEligible = true;
            //                     } else {
            //                         this.isEligible = false;
            //                     }
            //                 })

            //             // Clear out the old markers.
            //             markers.forEach((marker) => {
            //                 marker.setMap(null);
            //             });
            //             markers = [];

            //             // For each place, get the icon, name and location.
            //             const bounds = new google.maps.LatLngBounds();

            //             places.forEach((place) => {
            //             if (!place.geometry || !place.geometry.location) {
            //                 console.log("Returned place contains no geometry");
            //                 return;
            //             }

            //             const icon = {
            //                 url: place.icon,
            //                 size: new google.maps.Size(71, 71),
            //                 origin: new google.maps.Point(0, 0),
            //                 anchor: new google.maps.Point(17, 34),
            //                 scaledSize: new google.maps.Size(25, 25),
            //             };

            //             // Create a marker for each place.
            //             markers.push(
            //                 new google.maps.Marker({
            //                 map,
            //                 icon,
            //                 title: place.name,
            //                 position: place.geometry.location,
            //                 })
            //             );

            //             if (place.geometry.viewport) {
            //                 // Only geocodes have viewport.
            //                 bounds.union(place.geometry.viewport);
            //             } else {
            //                 bounds.extend(place.geometry.location);
            //             }
            //             });
            //             map.fitBounds(bounds);
            //         });
                    
            //     })
        }
    }
</script>